// 에이닷 영어 데이터
const ADOT_EN_DATA = {
  brandName: '에이닷 영어학원',
  brandLink: 'https://www.adotenglish.com/html/main',
  brandDetailInfo: {
    title: '온·오프라인 블렌디드\n1:1 영어학습 전문 브랜드',
    description:
      '에이닷 영어학원은 온·오프라인이 결합된 러닝 학습환경을 구축하여 최적의 개인 맞춤 학습을 제공하는 ‘1:1 프리미엄 교육 브랜드’ 입니다.\n90.98%의 높은 성적 향상률과 96.45%의 학습습관 개선율을 자랑하는 에이닷 영어학원은 전국 80개 지점에서 만나볼 수 있습니다.',
    img: {
      mobile: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_en_cover_mobile_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_en_blur_mobile_v2.png',
      },
      desktop: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_en_cover_desktop_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_en_blur_desktop_v2.png',
      },
    },
    linkTitle: '에이닷 영어학원 홈페이지 바로가기',
    link: 'https://www.adotenglish.com/html/main',
  },
};

// 에이닷 수학 데이터
const ADOT_MATH_DATA = {
  brandName: '에이닷 수학학원',
  brandLink: 'https://www.adotmath.com/html/main',
  brandDetailInfo: {
    title: '온라인\n1:1 수학학습 전문 브랜드',
    description:
      '에이닷 수학학원은 학생들에게 1:1 개별 맞춤 화상 관리를 제공하여 학생들을 수학 학습의 올바른 방향으로 안내합니다.\n모든 문제 풀이의 기본은 개념이라는 일념 하에\n확실한 개념완성을 돕기 위한 커리큘럼과 기억력을\n제공하는 휴먼 코치(담당 선생님)가 학생의 학습\n극대화하는 메타인지 테스트를 제공합니다.',
    img: {
      mobile: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_math_cover_mobile_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_math_blur_mobile_v2.png',
      },
      desktop: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_math_cover_desktop_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_math_blur_desktop_v2.png',
      },
    },
    linkTitle: '에이닷 수학학원 홈페이지 바로가기',
    link: 'https://www.adotmath.com/html/main',
  },
};

// 에이닷 온 데이터
const ADOT_ON_DATA = {
  brandName: '에이닷온 영어',
  brandLink: 'https://www.adotonenglish.com/student/index',
  brandDetailInfo: {
    title: '온라인\n1:1 영어학습 전문 브랜드',
    description:
      '에이닷온 영어는 공간의 제약을 벗어난 1:1 온라인 영어학원입니다.\n에이닷온 영어에서는 AI 학습 내비게이션으로\n최적의 학습 경로를 설정하고, 취약점을 분석해 주는\nAI 코치와 1:1 화상 수업으로 완전 맞춤 학습을\n제공하는 휴먼 코치(담당 선생님)가 학생의 학습\n성취를 위한 관리를 제공합니다.',
    img: {
      mobile: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_on_cover_mobile_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_on_blur_mobile_v2.png',
      },
      desktop: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_on_cover_desktop_v4.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/adot_on_blur_desktop_v2.png',
      },
    },
    linkTitle: '에이닷온 홈페이지 바로가기',
    link: 'https://www.adotonenglish.com/student/index',
  },
};

// 리딧 데이터
const READIT_DATA = {
  brandName: '리딧',
  brandLink: 'https://www.read-it.co.kr/html/main',
  brandDetailInfo: {
    title: '출판\n전문 브랜드',
    description:
      '리딧은 디쉐어 브랜드의 모든 자체 제작 교재의\n출판을 담당하는 출판 전문 브랜드입니다.\n리딧을 통해 내신과 수능을 관통하는 다양한\n콘텐츠들을 학생들에게 전달하고 있습니다.',
    img: {
      mobile: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/readit_cover_mobile_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/readit_blur_mobile_v2.png',
      },
      desktop: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/readit_cover_desktop_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/readit_blur_desktop_v2.png',
      },
    },
    linkTitle: '리딧 홈페이지 바로가기',
    link: 'https://www.read-it.co.kr/html/main',
  },
};

// 단끝 데이터
const VOCA_DATA = {
  brandName: '단끝',
  brandLink: 'https://www.vocaending.co.kr/html/main',
  brandDetailInfo: {
    title: '단어 학습\n전문 브랜드',
    description:
      '단끝은 학생들의 효율적인 영단어 암기를 위해 다양한\n학습 방법을 제공하는 ‘스마트 단어 학습 브랜드‘입니다.\n영단어장 베스트셀러 8권과 수능 및 평가원 모의고사\n3개년의 필수 영단어 약 9,600여 개를 앱 하나에\n모두 수록하여 언제 어디서든 효율적으로\n암기할 수 있습니다.',
    img: {
      mobile: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/voca_cover_mobile_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/voca_blur_mobile_v2.png',
      },
      desktop: {
        cover:
          'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/voca_cover_desktop_v2.png',
        blur: 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/voca_blur_desktop_v2.png',
      },
    },
    linkTitle: '단끝 홈페이지 바로가기',
    link: 'https://www.vocaending.co.kr/html/main',
  },
};

// 브랜드 토탈 데이터 리스트
export const BRANDS_DATA_LIST = [
  ADOT_EN_DATA,
  ADOT_MATH_DATA,
  ADOT_ON_DATA,
  READIT_DATA,
  VOCA_DATA,
];
