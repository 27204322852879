import { BrandMenuItemInterface } from '../types';

// 브랜드 메뉴 컴포넌트
export const BrandMenuItem = ({
  item,
  isSelected,
  setSelectedItem,
}: BrandMenuItemInterface) => {
  return (
    <a
      href={item.brandLink}
      target="_blank"
      rel="noreferrer"
      onMouseOver={setSelectedItem}
    >
      <li
        className={`${
          isSelected &&
          'bg-green text-white font-bold max-md:bg-green max-md:text-white max-md:font-bold'
        } badge bg-gray rounded-lg max-md:mr-2 max-md:bg-gray max-md:font-normal max-md:py-[14px] max-md:px-5 max-md:text-base cursor-pointer`}
      >
        <span>{item.brandName}</span>
      </li>
    </a>
  );
};
