import { useState, useEffect, useRef } from 'react';
import HistoryList from './HistoryList';

export const HistorySection = () => {
  const history = [
    2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2011,
  ];
  const [selectedYear, setSelectedYear] = useState(2023);
  const [scroll, setScroll] = useState(0);
  const [indicator, setIndicator] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const indicaotrRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current != null) {
      scrollRef.current.scrollLeft = scroll - 100;
    }
  }, [scroll]);

  useEffect(() => {
    if (indicaotrRef.current != null) {
      indicaotrRef.current.style.transform = `translate(${indicator * 80}px)`;
    }
  }, [indicator]);

  const yearHandle = (year: number, scroll: any, distance: any) => {
    setSelectedYear(year);
    setScroll(scroll);
    setIndicator(distance);
  };

  return (
    <section>
      <article className="max-w-screen-lg py-56 mx-auto max-md:py-24 max-md:px-6">
        <div className="pb-32 max-md:pb-12">
          <h3 className="pb-8 text-2xl font-bold text-green max-md:text-xl">
            D.SHARE History
          </h3>
          <p className="text-5xl font-bold text-black max-md:text-mo-lg">
            디쉐어가 <br className="hidden max-md:block" />
            걸어온 길을 <br className="hidden max-md:block" />
            소개합니다.
          </p>
        </div>
        <div className="block max-md:hidden">
          <img
            src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/history_v4.png"
            alt="D.SHARE 히스토리"
          />
        </div>
        <div className="hidden max-md:block max-md:z-0">
          <div className="block max-w-full mb-9">
            <div
              ref={scrollRef}
              className="relative flex overflow-auto text-xl font-bold text-gray-400 border-b cursor-pointer border-gray year-select"
            >
              {history.map((item, idx) => {
                let copy = [...history];
                return (
                  <div
                    className={`relative w-20 h-12 py-2 px-4 ${
                      idx === 0 && selectedYear === item
                        ? 'text-black'
                        : selectedYear === item
                        ? 'text-black'
                        : ''
                    }`}
                    data-distance={idx}
                    key={idx}
                    onClick={(e) => {
                      yearHandle(
                        copy[idx],
                        e.currentTarget.offsetLeft,
                        e.currentTarget.dataset.distance
                      );
                    }}
                  >
                    {copy[idx]}
                  </div>
                );
              })}
              <div ref={indicaotrRef} className="w-20 indicator"></div>
            </div>
          </div>
          <ul>{HistoryList[selectedYear]}</ul>
        </div>
      </article>
    </section>
  );
};
