import { useEffect, useState } from "react";
import {
  NEWS_TITLE_API,
  NEWS_DETAILS_LINK,
  NEWS_DETAILS_ITEM_LINK,
} from "../../constants/sites";
import axios from "axios";

type NewsItem = {
  id: number;
  title: string;
  date: string;
  publisher: string;
  created_ym: string;
};

type NewsData = {
  news: NewsItem[];
};

export const NewsSection = () => {
  const [news, setNews] = useState<NewsItem[]>([]);
  useEffect(() => {
    axios
      .get<NewsData>(NEWS_TITLE_API)
      .then(({ data }) => {
        setNews(data.news);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <section>
      <article id="scroll_3" className="py-56 max-w-screen-lg mx-auto max-md:py-24 max-md:px-6">
        <div className="pb-22 max-md:pb-14">
          <h3 className="text-2xl font-bold text-green pb-8 max-md:text-xl">
            D.SHARE News
          </h3>
          <p className="font-bold text-5xl text-black max-md:text-mo-lg">
            디쉐어의 <br className="hidden max-md:block" />
            새로운 소식을 <br className="hidden max-md:block" />
            알려드립니다.
          </p>
        </div>
        {news.map((item, idx) => {
          return (
            <div className="py-10 border-t border-gray" key={idx}>
              <div className="text-base mb-5">
                <span className="text-green font-bold mr-3">
                  {item.publisher}
                </span>
                <span className="text-gray-400 font-medium">
                  {item.created_ym}
                </span>
              </div>
              <div className="pr-8">
                <a
                  href={NEWS_DETAILS_ITEM_LINK + item.id}
                  target="_blank"
                  className="flex justify-between items-center"
                  rel="noreferrer"
                >
                  <p className="font-bold text-xl text-gray-700 text-news-gray max-md:text-base">
                    {item.title}
                  </p>
                  <svg
                    className="max-md:hidden block"
                    width="36"
                    height="20"
                    viewBox="0 0 36 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="hover:fill-arrow-gray"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M29.3431 0.929612L35.7071 7.29357C36.0976 7.6841 36.0976 8.31726 35.7071 8.70779L29.3431 15.0717C28.9526 15.4623 28.3195 15.4623 27.9289 15.0717C27.5384 14.6812 27.5384 14.0481 27.9289 13.6575L32.5858 9.00068H1C0.447715 9.00068 0 8.55296 0 8.00068C0 7.4484 0.447715 7.00068 1 7.00068H32.5858L27.9289 2.34383C27.5384 1.9533 27.5384 1.32014 27.9289 0.929612C28.3195 0.539088 28.9526 0.539088 29.3431 0.929612Z"
                      fill="#EEEEEE"
                    />
                  </svg>
                </a>
              </div>
            </div>
          );
        })}
        <div className="border-t border-gray pt-8">
          <a
            href={NEWS_DETAILS_LINK}
            target="_blank"
            className="flex justify-end items-center text-right text-xl font-bold max-md:text-base"
            rel="noreferrer"
          >
            News 자세히 보기
            <div className='h-6'>
              <img src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/arrow.png" alt="News 자세히 보기"/>
            </div>
          </a>
        </div>
      </article>
    </section>
  );
};
