import { useState } from "react";
import { IoMenu, IoClose } from "react-icons/io5";
import { NavItems } from './NavItems';
import { NavLogo } from './NavLogo';

export const Nav = () => {

  const [menuOpen, setMenuOpen] = useState(true);
  const menu = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <nav className="h-15 bg-white max-w-screen-lg mx-auto md:flex md:items-center md:justify-between">
      <div className="flex justify-between items-center max-md:px-6 max-md:py-5">
        <NavLogo></NavLogo>
        <span className="text-3xl cursor-pointer md:hidden block max-md:w-6 h-6 max-md:flex max-md:items-center">
          {menuOpen ? <IoMenu onClick={menu}></IoMenu> : <IoClose onClick={menu}></IoClose>}
        </span>
      </div>
      <NavItems menuOpen={menuOpen}></NavItems>
    </nav>
  );
}