import React from 'react';
import { render } from 'react-dom';

import { Wrapper } from './components/Wrapper/Wrapper';
import { Header } from './components/Header/Header';
import { MainPage } from './components/MainPage/MainPage';
import { Footer } from './components/Footer/Footer';

function App() {
  
  return (
    <Wrapper>      
      <Header></Header>
      <MainPage></MainPage>
      <Footer></Footer>
    </Wrapper>
  );
}

export default App;
