const IS_PROD = process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'prod';
const URL_PREFIX = IS_PROD ? 'www' : 'dev';

export const SITES = {
    'corp' : `https://${URL_PREFIX}.dshare.co.kr`,
    'adot' : `https://${URL_PREFIX}.adotenglish.com`,
    'adoton' : `https://${URL_PREFIX}.adotonenglish.com`,
    'adotmath' : `https://${URL_PREFIX}.adotmath.com`,
    'voca' : `https://${URL_PREFIX}.vocaending.co.kr`,
    'readit' : `https://${URL_PREFIX}.read-it.co.kr`
};

export const NEWS_DETAILS_LINK = `https://${URL_PREFIX}.dshare.co.kr/html/body/promote/media`;
export const NEWS_DETAILS_ITEM_LINK = NEWS_DETAILS_LINK + '?type=view&fq_id=';

export const NEWS_TITLE_API = `https://${URL_PREFIX}.dshare.co.kr/api/news/title/recent/5`;
export const STUDENT_COUNT_API = `https://${URL_PREFIX}.dshare.co.kr/api/homepage/studentCount`;

