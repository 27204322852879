import {useEffect, useState} from 'react';
import { STUDENT_COUNT_API } from "../../constants/sites"
import axios from 'axios';

const ArticleSection = () => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    axios.get(STUDENT_COUNT_API)
    .then(({data}) => {
      setCount(data.count)
    })
  }, []);

  return (
    <article className="absolute w-full h-full flex items-center top-1/2 bottom-2/4 -translate-y-1/2 max-md:h-full max-md:px-6 max-md:py-11">
      <div className='w-[1060px] mx-auto'>
      <div className="md:flex md:justify-between md:items-center md:mr-[154px] text-white max-md:absolute max-md:top-1/2 max-md:-translate-y-1/2">
        <h2 className="max-md:text-mo-lg font-bold text-[2.75rem] max-md:pt-3">대한민국을 선도하는<br/>온·오프라인 블렌디드<br/>에듀테크 기업</h2>
        <div className="max-w-[32.5rem] text-xl max-md:text-base max-md:pt-9">
          <p>디쉐어는 초중고 학생들의 성공적인
            <br className="hidden max-md:block"/> 학습성취를 돕기 위해
            <br className="max-md:hidden block"/> 전국 80개 직영 학원과 
            <br className="hidden max-md:block"/> 온라인 학습환경을 <br className="max-md:hidden block"/>구축한 에듀테크 기업입니다.
          </p>
          <p className="pt-7 max-md:pt-9">디쉐어는 1:1 맞춤 교육과 누적 {count}명 수강생의
            <br/>학습 데이터를 기반으로 한 AI 기술을 통해 
            <br/>학생들의 효율적인 학습 목표를 실현합니다.
          </p>
        </div>
      </div>
      </div>
    </article>
  );
}

export const VideoSection = () => {
  let videoUrl = `https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/video/`;

  const [videoRoute, setVideoRoute] = useState([
    videoUrl + (window.innerWidth > 768 ? 'PC_1_slogan.mp4' : 'MO_1_slogan.mp4'),
    videoUrl + (window.innerWidth > 768 ? 'PC_2_presentation.mp4' : 'MO_2_presentation.mp4')
  ]);
  const handleResize = () => {
    setVideoRoute([
      videoUrl + (window.innerWidth > 768 ? 'PC_1_slogan.mp4' : 'MO_1_slogan.mp4'),
      videoUrl + (window.innerWidth > 768 ? 'PC_2_presentation.mp4' : 'MO_2_presentation.mp4'),
    ]);    
  }
  
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);  

  return (
    <>
      <section>
        <video src={videoRoute[0]} loop={true} muted={true} autoPlay={true} playsInline={true} className="md:block block w-full"></video>
      </section>
      <section className="relative">
        <video src={videoRoute[1]} loop={true} muted={true} autoPlay={true} playsInline={true} className="md:block block w-full"></video>
        <ArticleSection></ArticleSection>
      </section>
    </>
  );
};

