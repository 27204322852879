import React, { memo } from 'react';
import { ComponentPropsType } from '../../types';

export const Wrapper = memo(({ children }: ComponentPropsType) => {
  return (
    <div className="container font-['Pretendard'] max-w-screen-xl text-content-black max-md:text-base max-md:overflow-hidden">
      {children}
    </div>
  );
});
