import { Link } from "react-scroll";

type NavItemsType = {
  menuOpen: boolean
}

export const NavItems = ({menuOpen}: NavItemsType) => {
  
  const menuCloseCss = menuOpen ? '' : 'top-[75px] opacity-100';
  const menuModel = [
    '회사소개',
    'Brand',
    '채용',
    'NEWS',
    'CSR'
  ];
  const menuList = menuModel.map((item, idx) => 
    <li className="mr-11 max-md:mr-0 max-md:py-4 cursor-pointer" key={idx}>
      <Link to={'scroll_' + String(idx)} className="hover-dshare-green" spy={true} smooth={true}>{item}</Link>
    </li>
  );

  return (
    <ul className={"md:flex md:items-center z-[-1] md:z-auto md:static absolute bg-white w-full left-0 md:w-auto md:py-0 p-4 md:pl-0 md:opacity-100 opacity-0 top-[-400px]" + menuCloseCss}>     
      {menuList}
    </ul>
  );
}