import { TitleSection } from './TitleSection';
import { VideoSection } from './VideoSection';
import { HistorySection } from './HistorySection';
import { CardSection } from './cardSection/CardSection';
import { RecruitSection } from './RecruitSection';
import { NewsSection } from './NewsSection';
import { CSRSection } from './CSRSection';

export const MainPage = () => {
  return (
    <main>
      <TitleSection />
      <VideoSection />
      <HistorySection />
      <CardSection />
      <RecruitSection />
      <NewsSection />
      <CSRSection />
    </main>
  );
};
