export const NavLogo = () => {

  const imageRoute = {
    'dshare-logo': 'https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/dshare.png'
  }
  return (
    <span className="text-2xl cursor-pointer max-md:h-5 max-md:flex">
      <img className="h-7 inline max-md:h-5" src={imageRoute["dshare-logo"]} alt="D.SHARE 로고"/>
    </span>
  );
}