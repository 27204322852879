export const TitleSection = () => {

  return (
    <section id="scroll_0" className="pt-20">
      <article className="pt-20 max-md:pt-14 max-md:pb-8 max-w-screen-lg mx-auto max-md:px-6">
        <h1 className="text-6xl font-black text-black max-md:text-[32px]">D.SHARE</h1>
        <h2 className="pt-7 pb-14 text-[1.75rem] max-md:text-base max-md:pb-0">대한민국을 선도하는 <span className="font-bold"><br className="md:hidden block" />온·오프라인 블렌디드 에듀테크 기업</span>
        </h2>
      </article>
    </section>
  );
};