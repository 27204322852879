import { useState } from 'react';
import { BrandDetailInterface } from '../types';

// 브랜드 상세 컴포넌트, 모바일 & 데스크탑 이미지 분리
export const BrandDetailInfoItem = ({
  item,
}: {
  item: BrandDetailInterface;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <article
      key={item.title}
      className={'rounded-md relative max-md:px-6 w-full cursor-pointer'}
    >
      <article className={'relative'}>
        {/* 모바일 이미지 */}
        <div className={'md:hidden'} onClick={() => setIsOpen(!isOpen)}>
          <img
            src={item.img.mobile.cover}
            className={`${!isOpen ? 'visible' : 'invisible'} w-full`}
            alt={item.title}
          />
          <img
            src={item.img.mobile.blur}
            className={`${
              isOpen ? 'visible' : 'invisible'
            } absolute left-0 top-0 w-full`}
            alt={item.title}
          />
        </div>
        {/* 데스크탑 이미지 */}
        <div
          className={'max-md:hidden relative'}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            src={item.img.desktop.cover}
            className={`${!isOpen ? 'visible' : 'invisible'}`}
            alt={item.title}
          />
          <img
            src={item.img.desktop.blur}
            className={`${
              isOpen ? 'visible' : 'invisible'
            } absolute left-0 top-0`}
            alt={item.title}
          />
        </div>
        <div className={'absolute top-[14%] p-10 max-md:p-6'}>
          <h3 className="text-3xl font-bold whitespace-pre-wrap max-md:text-2xl">
            {item.title}
          </h3>
          {isOpen && (
            <div>
              <p className="text-base whitespace-pre-wrap pt-14 md:text-xl max-md:pt-4 max-md:whitespace-normal">
                {item.description}
              </p>
              <a
                href={item.link}
                className="block text-xl font-bold pt-14 text-green max-md:pt-5 max-md:text-base"
                target="_blank"
                rel="noreferrer"
              >
                {item.linkTitle}
              </a>
            </div>
          )}
        </div>
      </article>
    </article>
  );
};
