export const Footer = () => {
  const bonopen = () => {
    let url =
      "http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=6388100269";
    window.open(url, "communicationViewPopup", "width=750, height=700;");
  };

  return (
    <footer className="bg-black px-4">
      <article className="max-w-screen-lg mx-auto py-20">
        <div className="flex content-start max-md:block">
          <div className="mr-14 max-md:h-5 max-md:mr-0">
            <img
              src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/footer_logo.png"
            />
          </div>
          <div>
            <p className="text-xs text-white max-md:pt-5">
            서울특별시 성동구 성수일로12길 35, 6-8층｜사업자등록번호 : 638-81-00269 
              <span className="font-bold cursor-pointer" onClick={bonopen}>
                [정보확인]
              </span>
              <br />
              통신판매신고번호 2023-서울성동-2308호
              <br className="hidden max-md:inline" />{" "}
              <span className="max-md:hidden inline">｜</span>
              <a
                href="https://www.dshare.co.kr/html/body/policy/privacy"
                target="_blank"
                rel="noreferrer"
              >
                <span className="font-bold">[개인정보처리방침]</span>
              </a>
              <br />
              개인정보관리책임자 : 박승훈｜대표이사 : 이수옥
              <br className="hidden max-md:inline" />
              <span className="max-md:hidden inline">｜</span>대표전화 :
              1644-6466
              <br />
              <span className="max-md:hidden inline"></span>대표메일 :
              support@dshare.co.kr
              <br />
              <br />
              Copyright 2015 주식회사 디쉐어. All rights reserved
            </p>
          </div>
        </div>
      </article>
    </footer>
  );
};
