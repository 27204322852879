type year = Record<number, JSX.Element>;

const HistoryList: year = {
  2023: (
    <div>
      <li className="mb-9">
        <h4 className="mb-3 font-bold text-gray-400">2023.12</h4>
        <p className="text-xl">성수동 사옥 이전</p>
      </li>
    </div>
  ),
  2022: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2022.11</h4>
          <p className="text-xl">
            한경비즈니스 주관,
            <br />
            ‘2022 한국소비자평가 1위
            <br />
            교육(AI에듀테크) 부문’ 수상
            <br />
          </p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2022.11</h4>
          <p className="text-xl">
            한경비즈니스 주관,
            <br />
            ‘2022 한국소비자감동지수 1위
            <br />
            교육(수능) 부문’ 수상
            <br />
          </p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2022.08</h4>
          <p className="text-xl">
            전국 직영학원 <span className="font-bold">80호점</span> 돌파
          </p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2022.03</h4>
          <p className="text-xl">
            <span className="font-bold">'에이닷온' </span>론칭
          </p>
        </li>
      </div>
    </>
  ),
  2021: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2021.12</h4>
          <p className="text-xl">
            전국 직영학원 ‘AI 개인화 서비스(Syntax AI)’
            <br /> 론칭
          </p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2021.06</h4>
          <p className="text-xl">
            <span className="font-bold">'에이닷 수학학원' </span>론칭
          </p>
        </li>
      </div>
    </>
  ),
  2020: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2020.09</h4>
          <p className="text-xl">
            AI 기반 내신 학습 솔루션 ‘내모
            <br />
            (내신의 모든 것)’ 출시
          </p>
        </li>
      </div>
    </>
  ),
  2019: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2019.10</h4>
          <p className="text-xl">
            VIG 파트너스 투자 유치
            <br />
            (기업가치 3,300억 원)
          </p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2019.07</h4>
          <p className="text-xl">수강생 20,000명 돌파</p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2019.05</h4>
          <p className="text-xl">KRI 한국기록원 3개 부문 기록 인증</p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2019.03</h4>
          <p className="text-xl">롯데월드타워로 사옥 이전</p>
        </li>
      </div>
    </>
  ),
  2018: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2018.12</h4>
          <p className="text-xl">전국 직영학원 60호점 돌파</p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2018.06</h4>
          <p className="text-xl">
            중앙일보 주관, ‘대한민국 브랜드 대상’
            <br />
            3년 연속 수상
          </p>
        </li>
      </div>
    </>
  ),
  2017: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2017.10</h4>
          <p className="text-xl">
            단어 학습 전문 브랜드 <span className="font-bold">'단끝'</span> 론칭
          </p>
        </li>
      </div>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2017.06</h4>
          <p className="text-xl">중앙일보 주관, ‘대한민국 브랜드 대상' 수상</p>
        </li>
      </div>
    </>
  ),
  2016: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2016.12</h4>
          <p className="text-xl">전국 직영학원 50호점 돌파</p>
        </li>
        <div>
          <li className="mb-9">
            <h4 className="mb-3 font-bold text-gray-400">2016.12</h4>
            <p className="text-xl">
              출판 전문 브랜드 <span className="font-bold">'리딧'</span> 론칭
            </p>
          </li>
        </div>
        <div>
          <li className="mb-9">
            <h4 className="mb-3 font-bold text-gray-400">2016.06</h4>
            <p className="text-xl">
              중앙일보 주관, ‘대한민국 브랜드 대상' 수상
            </p>
          </li>
        </div>
      </div>
    </>
  ),
  2015: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2015.12</h4>
          <p className="text-xl">법인 설립</p>
        </li>
        <div>
          <li className="mb-9">
            <h4 className="mb-3 font-bold text-gray-400">2015.12</h4>
            <p className="text-xl">전국 직영학원 40호점 돌파</p>
          </li>
        </div>
        <div>
          <li className="mb-9">
            <h4 className="mb-3 font-bold text-gray-400">2015.10</h4>
            <p className="text-xl">수강생 10,000명 돌파</p>
          </li>
        </div>
        <div>
          <li className="mb-9">
            <h4 className="mb-3 font-bold text-gray-400">2015.07</h4>
            <p className="text-xl">
              한경비즈니스 주관, ‘2015 한국소비자만족지수 1위 교육(수능) 부문’
              수상
              <br />
            </p>
          </li>
        </div>
      </div>
    </>
  ),
  2014: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2014.12</h4>
          <p className="text-xl">전국 직영학원 20호점 돌파</p>
        </li>
      </div>
    </>
  ),
  2011: (
    <>
      <div>
        <li className="mb-9">
          <h4 className="mb-3 font-bold text-gray-400">2011.01</h4>
          <p className="text-xl">
            <span className="font-bold">‘에이닷 영어학원’ </span>론칭
          </p>
        </li>
      </div>
    </>
  ),
};

export default HistoryList;
