export const CSRSection = () => {
    return (
      <section className="bg-CSR bg-cover bg-center">
        <article
          id="scroll_4"
          className="py-56 max-w-screen-lg mx-auto max-md:py-24 max-md:px-6"
        >
          <h3 className="text-2xl font-bold text-green pb-8 max-md:text-xl">
            D.SHARE CSR
          </h3>
          <div className="font-bold text-5xl text-white max-md:text-mo-lg">
            <p className="pb-24 max-md:pb-12">
              디쉐어는 <br className="hidden max-md:block" />더 나은 세상을
              <br className="hidden max-md:block" /> 만들고자 합니다.
            </p>
            <p className="leading-[5rem] text-white text-opacity-60 max-md:text-mo-lg">
              전 세계{" "}
              <span className="text-white">
                49개국
                <br className="hidden max-md:block" />
              </span>{" "}
              총 <span className="text-white">1,460명</span>의 아이들에
              <br />
              누적 <span className="text-white">4,083,478,500원</span>을
              기부하였습니다.
            </p>
            <span className="font-normal text-white text-opacity-60 text-base">
              *2014년 11월 부터의 누적 기부금 총계
            </span>
          </div>
        </article>
      </section>
    );
  };