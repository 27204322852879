import { useState } from 'react';
import { BRANDS_DATA_LIST } from './constants';
import { BrandMenuItem } from './components/BrandMenuItem';
import { BrandDetailInfoItem } from './components/BrandDetailInfoItem';

export const CardSection = () => {
  // NOTE : Hover된 menu item index
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);

  const setSelectedItem = (index: number) => {
    setSelectedMenuIndex(index);
  };

  return (
    <section className="bg-gray-50">
      <article
        id="scroll_1"
        className="max-w-screen-lg py-56 mx-auto max-md:py-24"
      >
        <div className="pb-22 max-md:pb-14 max-md:px-6">
          <h3 className="pb-8 text-2xl font-bold text-green max-md:text-xl">
            D.SHARE Brand
          </h3>
          <p className="text-5xl font-bold text-black max-md:text-mo-lg">
            디쉐어는 진정성 있는 <br className="hidden max-md:block" />
            교육 서비스를 <br className="hidden max-md:block" />
            제공합니다.
          </p>
        </div>
        {/* 브랜드 메뉴 리스트 */}
        <div className="mb-8 max-md:overflow-x-scroll max-md:ml-6">
          <ul className="flex max-md:overflow-x-scroll w-max">
            {BRANDS_DATA_LIST.map((item, index) => {
              return (
                <BrandMenuItem
                  item={item}
                  isSelected={selectedMenuIndex === index}
                  key={item.brandName}
                  setSelectedItem={() => setSelectedItem(index)}
                />
              );
            })}
          </ul>
        </div>
        {/* 브랜드 상세 리스트 (grid) */}
        <div
          className={
            'grid grid-cols-2 gap-y-15 gap-x-10 max-md:grid-cols-1 max-md:gap-y-6'
          }
        >
          {BRANDS_DATA_LIST.map((item) => {
            return (
              <BrandDetailInfoItem
                item={item.brandDetailInfo}
                key={item.brandName}
              />
            );
          })}
        </div>
      </article>
    </section>
  );
};
