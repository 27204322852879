export const RecruitSection = () => {
  return (
    <section id="scroll_2" className="bg-gray">
    <article className="py-56 max-w-screen-lg mx-auto max-md:py-24 max-md:px-6">
      <div className="pb-22 max-md:pb-12">
        <h3 className="text-2xl font-bold text-green pb-8 max-md:text-xl">D.SHARE People</h3>
        <p className="font-bold text-5xl text-black max-md:text-mo-lg">디쉐어는 <br className="hidden max-md:block" />이런 분들과 <br className="hidden max-md:block" />함께 하고 있습니다.</p>
      </div>
      <div>
        <p className="text-4xl font-bold text-gray-400 leading-bg-loose pb-9 max-md:pb-14 max-md:text-xl">
          교육의 <span className="text-green">본질</span>을 <span className="text-green">알고</span><br />
          교육의 <span className="text-green">가치</span>를 전하기 위해 <span className="text-green">몰입</span>하며<br />
          교육의 <span className="text-green">한계</span>를 지우기 위해 <span className="text-green">성장</span>하는 인재<br />
        </p>
        <div className="md:flex md:justify-between md:items-center md:h-72">
          <div className="md:w-4/12 md:h-full md:mr-5 bg-white md:px-10 md:pt-10 rounded-md max-md:mb-5 max-md:px-7 max-md:py-8">
            <span className="py-2 px-3 bg-green font-bold text-white text-base rounded-sm">Sincerity</span>
            <section className="text-black pt-6">
              <h3 className="pb-8 font-bold text-3xl">진정성</h3>
              <p className="text-xl max-md:text-lg">교육의 본질을 알고,<br />진심으로 학생을 위하는 마음</p>
            </section>
          </div>
          <div className="md:w-4/12 md:h-full md:mr-5 bg-white md:px-10 md:pt-10 rounded-md max-md:mb-5 max-md:px-7 max-md:py-8">
            <span className="py-2 px-3 bg-green font-bold text-white text-base rounded-sm">Commitment</span>
            <section className="text-black pt-6">
              <h3 className="pb-8 font-bold text-3xl">몰입</h3>
              <p className="text-xl max-md:text-lg">진정한 교육의 가치를<br />실현하기 위해 몰입하는 자세</p>
            </section>
          </div>
          <div className="md:w-4/12 md:h-full bg-white md:px-10 md:pt-10 rounded-md max-md:mb-5 max-md:px-7 max-md:py-8">
            <span className="py-2 px-3 bg-green font-bold text-white text-base rounded-sm">Growth</span>
            <section className="text-black pt-6">
              <h3 className="pb-8 font-bold text-3xl">성장</h3>
              <p className="text-xl max-md:text-lg">변화에 도태되지 않고,<br />개인과 조직의 동반성장에<br />기여하려 노력하는 자세</p>
            </section>
          </div>
        </div>
        <div className="pt-8">
          <a
          href="https://www.notion.so/D-SHARE-9e1ce6f381ee49c7af50cf3af3741c0f"
          target="_blank"
          className="flex justify-end items-center text-right text-xl font-bold max-md:text-base"
          rel="noreferrer"
          >채용 자세히 보기
          <div className='h-6'>
            <img src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/arrow.png" alt="채용 자세히 보기" />
          </div>
          </a>
        </div>
      </div>
    </article>
  </section>
  );
};
